const HomePageMenu = ({ title, description }) => {
  return (
    <div className="row mt-3">
      <div className="col">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default HomePageMenu;

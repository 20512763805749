import React, { useState, useEffect } from "react";

const Botscard = ({ snapshot }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="container mt-4">
      <div className="pb-2">Number of Bots: {snapshot.length}</div>
      {snapshot.map((bot, index) => (
        <div className="card mb-3" key={index}>
          <div className="row no-gutters">
            <div className="col-md-4 p-4">
              <img
                src={
                  bot.avatar || "https://cdn.discordapp.com/embed/avatars/0.png"
                }
                className="card-img rounded-circle"
                alt={bot.name}
                style={
                  windowWidth > 470
                    ? { width: "220px", height: "220px" }
                    : { width: "120px", height: "120px" }
                }
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">
                  {index + 1}) {bot.name}
                </h5>
                <p className="card-text">Role: "{bot.role}"</p>
                <p className="card-text">Permissions:</p>
                <ul className="list-group">
                  {bot.permissions.map((permission, i) => (
                    <li key={i} className="list-group-item">
                      {permission}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Botscard;

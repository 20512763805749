import { useState, useEffect } from "react";

import SnapShotPageIntro from "../components/SnapShotsPageIntro";
import CurrentSnapShot from "../components/CurrentSnapshot";
import ManualSnapshot from "../components/ManualSnapshot";
import PeriodicSnapshot from "../components/PeriodicSnapshot";
const environment = process.env.NODE_ENV;

const SnapShots = () => {
  const [selected, select] = useState(null);
  const [logsData, setLogsData] = useState(null);

  async function fetchUrl(url) {
    const response = await fetch(url);
    const result = await response.json();
    setLogsData(result);
  }

  useEffect(() => {
    switch (selected) {
      case "current":
        fetchUrl(
          environment === "production"
            ? `https://api.watcher.humblefool13.dev/snapshots/current`
            : `http://localhost:3001/snapshots/current`
        );
        break;
      case "manual":
        fetchUrl(
          environment === "production"
            ? `https://api.watcher.humblefool13.dev/snapshots/manual`
            : `http://localhost:3001/snapshots/manual`
        );
        break;
      case "periodic":
        fetchUrl(
          environment === "production"
            ? `https://api.watcher.humblefool13.dev/snapshots/periodic`
            : `http://localhost:3001/snapshots/periodic`
        );
        break;
      case "changes":
        window.location.replace("/changes");
        break;
      default:
        break;
    }
  }, [selected]);

  return (
    <div className="container">
      {selected ? <></> : <SnapShotPageIntro select={select} />}
      {selected === "current" ? <CurrentSnapShot logsData={logsData} /> : <></>}
      {selected === "manual" ? <ManualSnapshot logsData={logsData} /> : <></>}
      {selected === "periodic" ? (
        <PeriodicSnapshot logsData={logsData} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SnapShots;

const ChangeDisplay = ({ data }) => {
  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function renderRolesTable(oldPermissions, newPermissions) {
    const oldPerms = oldPermissions[0];
    const newPerms = newPermissions[0];
    const maxLength = Math.max(oldPerms.length, newPerms.length);
    return (
      <div className="row justify-content-center">
        <div className="col-md-8">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Old Permissions</th>
                <th>New Permissions</th>
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: maxLength }).map((_, index) => (
                <tr key={index}>
                  <td>{index < oldPerms.length ? oldPerms[index] : ""}</td>
                  <td>{index < newPerms.length ? newPerms[index] : ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function renderChannelTable(data) {
    return (
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <h2>Before</h2>
            {renderChannelPermissions(data.oldPermissions)}
          </div>
          <div class="col-md-6">
            <h2>After</h2>
            {renderChannelPermissions(data.newPermissios)}
          </div>
        </div>
      </div>
    );
  }

  function renderChannelPermissions(permissionOverwrites) {
    if (!permissionOverwrites.length) {
      return (
        <div className="row justify-content-center">
          <div className="col-md-10">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>No Permissions</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      );
    }
    const roles = permissionOverwrites.filter(
      (overwrite) => overwrite[0] === "role"
    );
    const users = permissionOverwrites.filter(
      (overwrite) => overwrite[0] === "user"
    );
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>Type</th>
                  <th style={{ width: "20%" }}>Name</th>
                  <th>Allowed</th>
                  <th>Denied</th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role, index) => {
                  let maxPermsCount =
                    role[2].length > role[3].length
                      ? role[2].length
                      : role[3].length;
                  const allowed = role[2];
                  const denied = role[3];
                  let components = [];
                  if (!index) {
                    components.push(
                      <tr>
                        <td rowSpan={rowSpanCalculator(roles)}>Roles</td>
                        <td rowSpan={maxPermsCount || 1}>{role[1]}</td>
                        <td>{allowed.length ? allowed[0] : ""}</td>
                        <td>{denied.length ? denied[0] : ""}</td>
                      </tr>
                    );
                    for (let i = 1; i < maxPermsCount; i++) {
                      components.push(
                        <tr>
                          <td>{allowed.length >= i ? allowed[i] : ""}</td>
                          <td>{denied.length >= i ? denied[i] : ""}</td>
                        </tr>
                      );
                    }
                  } else {
                    for (let i = 0; i < maxPermsCount; i++) {
                      if (!i) {
                        components.push(
                          <tr>
                            <td rowSpan={maxPermsCount || 1}>{role[1]}</td>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      } else {
                        components.push(
                          <tr>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      }
                    }
                  }
                  return components;
                })}
                {users.map((user, index) => {
                  let maxPermsCount =
                    user[2].length > user[3].length
                      ? user[2].length
                      : user[3].length;
                  const allowed = user[2];
                  const denied = user[3];
                  let components = [];
                  if (!index) {
                    components.push(
                      <tr>
                        <td rowSpan={rowSpanCalculator(users)}>Users</td>
                        <td rowSpan={maxPermsCount || 1}>{user[1]}</td>
                        <td>{allowed.length ? allowed[0] : ""}</td>
                        <td>{denied.length ? denied[0] : ""}</td>
                      </tr>
                    );
                    for (let i = 1; i < maxPermsCount; i++) {
                      components.push(
                        <tr>
                          <td>{allowed.length >= i ? allowed[i] : ""}</td>
                          <td>{denied.length >= i ? denied[i] : ""}</td>
                        </tr>
                      );
                    }
                  } else {
                    for (let i = 0; i < maxPermsCount; i++) {
                      if (!i) {
                        components.push(
                          <tr>
                            <td rowSpan={maxPermsCount || 1}>{user[1]}</td>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      } else {
                        components.push(
                          <tr>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      }
                    }
                  }
                  return components;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function rowSpanCalculator(array) {
    let rows = 0;
    array.forEach((entity) => {
      const allowed = entity[2].length;
      const denied = entity[3].length;
      if (allowed > denied) {
        rows += allowed;
      } else if (allowed === 0 && denied === 0) {
        rows += 1;
      } else {
        rows += denied;
      }
    });
    return rows;
  }

  return (
    <div className="container">
      <div className="row justify-content-center mt-3 mb-4">
        <h4>Changes Detail</h4>
      </div>
      <div className="row justify-content-center mt-3 mb-4">
        <h5>Executor:</h5>
        <a
          href={`https://discord.com/users/${data.executor[1]}`}
          target="_blank"
          rel="noreferrer"
        >
          <h6>{data.executor[0]}</h6>
        </a>
      </div>
      <div className="row justify-content-center mt-3 mb-4">
        <h5>Target:</h5>
        <h6>{data.target[1] === "role" ? "Role" : "Channel"}</h6>
        <h6>{`Name: ${data.target[0]}`}</h6>
      </div>
      <div className="row justify-content-center mt-3 mb-4">
        <h5>Action:</h5>
        <h6>{capitalize(data.type)}</h6>
      </div>
      {data.type === "update" ? (
        data.target[1] === "role" ? (
          <>
            <div className="row justify-content-center mt-3 mb-4">
              <h5>Changes:</h5>
            </div>
            {renderRolesTable(data.oldPermissions, data.newPermissios)}
          </>
        ) : (
          <>
            <div className="row justify-content-center mt-3 mb-4">
              <h5>Changes:</h5>
            </div>
            {renderChannelTable(data)}
          </>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChangeDisplay;

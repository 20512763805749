const Dropdown = ({
  setChoseChange,
  logsData,
  setShowChannels,
  setShowRoles,
}) => {
  const handleOptionSelect = (event) => {
    const stringSelected = event.target.value;
    setChoseChange(stringSelected);
    setShowChannels(false);
    setShowRoles(false);
  };

  return (
    <div className="dropdown mt-5">
      <select
        className="form-select"
        aria-label="Select Snapshot"
        onChange={handleOptionSelect}
      >
        <option value="" disabled selected>
          Pick the Change to show details
        </option>
        {logsData ? (
          logsData?.data?.map((log) => (
            <option key={log.logID} value={log.logID}>
              {`${log.executor[0]} ${log.type}d ${log?.target[0]}`}
            </option>
          ))
        ) : (
          <></>
        )}
      </select>
    </div>
  );
};

export default Dropdown;

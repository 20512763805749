import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ComparePage from "./pages/Compare";
import HomePage from "./pages/HomePage";
import ChangesPage from "./pages/Changes";
import SnapShots from "./pages/SnapShots";
import Bots from "./pages/Bots";
import LogsPage from "./pages/Logs";

import NavBar from "./components/NavBar";

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/snapshots" element={<SnapShots />} />
          <Route path="/changes" element={<ChangesPage />} />
          <Route path="/bots" element={<Bots />} />
          <Route path="/compare" element={<ComparePage />} />
          <Route path="/logs" element={<LogsPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

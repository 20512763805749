/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
const environment = process.env.NODE_ENV;

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [actionType, setActionType] = useState(null);
  const [actionTypePhaseTwo, setActionTypePhaseTwo] = useState(null);
  const [userType, setUserType] = useState([]);
  const [timeFrame, setTimeFrame] = useState(null);
  const [clicked, setClicked] = useState(null);

  const phaseTwoOptions = {
    "Server Changes": [
      "Update Server Name",
      "Onboarding Created",
      "Onboarding Updated",
      "Invite Created",
      "Invite Deleted",
      "Emoji Created",
      "Emoji Updated",
      "Emoji Deleted",
      "Sticker Created",
      "Sticker Updated",
      "Sticker Deleted",
    ],
    "Role Additions and Deletions": ["Role Addition", "Role Deletion"],
    "Role Permission Changes": [],
    "Channel Additions and Deletions": ["Channel Addition", "Channel Deletion"],
    "Channel Permission Changes": [],
    Punishments: ["Kicked User", "Banned User", "Timed Out User", "Remove Ban", "Remove Timeout"],
    "Messages Changes": ["Message Deleted", "Message Pinned"],
    "Thread Changes": ["Thread Created", "Thread Deleted", "Thread Updated"],
    "Integration Changes": [
      "Integration Created",
      "Integration Deleted",
      "Integration Updated",
    ],
    "Stages & Events": [
      "Event Created",
      "Event Deleted",
      "Event Updated",
      "Stage Created",
      "Stage Deleted",
      "Stage Updated",
    ],
    "Member Changes": ["Nickname Change", "Role Change"],
  };

  async function fetchLogs() {
    const response = await fetch(
      environment === "production"
        ? `https://api.watcher.humblefool13.dev/logs`
        : `http://localhost:3001/logs`
    );
    const result = await response.json();
    setLogs(result.data);
    setFilteredLogs(result.data);
  }

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleActionTypeChange = (type) => {
    setActionType(type);
    setActionTypePhaseTwo(null);
  };

  const handleUserTypeChange = (type) => {
    const updatedTypes = userType.includes(type)
      ? userType.filter((t) => t !== type)
      : [...userType, type];
    setUserType(updatedTypes);
  };

  const handleTimeFrameChange = (frame) => {
    setTimeFrame(frame);
  };

  useEffect(() => {
    filterLogs();
  }, [actionType, userType, timeFrame, actionTypePhaseTwo]);

  const filterLogs = () => {
    setClicked(null);
    let filtered = logs;
    if (actionType) {
      filtered = filtered.filter((logI) => logI.data.phaseOne === actionType);
    }
    if (actionTypePhaseTwo) {
      filtered = filtered.filter(
        (logI) => logI.data.phaseTwo === actionTypePhaseTwo
      );
    }
    if (userType?.length > 0) {
      filtered = filtered.filter((logI) => {
        const executor = logI.executor;
        let display = true;
        if (userType.includes("Admin")) {
          if (display) display = executor.isAdmin;
        }
        if (userType.includes("Moderators")) {
          if (display) display = executor.isMod;
        }
        if (userType.includes("Bots")) {
          if (display) display = executor.isBot;
        }
        return display;
      });
    }
    if (timeFrame) {
      const now = Date.now();
      let minTimestamp = 0;
      switch (timeFrame) {
        case "1hour":
          minTimestamp = now - 1 * 60 * 60 * 1000;
          break;
        case "24hours":
          minTimestamp = now - 24 * 60 * 60 * 1000;
          break;
        case "1week":
          minTimestamp = now - 7 * 24 * 60 * 60 * 1000;
          break;
        case "1month":
          minTimestamp = now - 30 * 24 * 60 * 60 * 1000;
          break;
        case "6months":
          minTimestamp = now - 6 * 30 * 24 * 60 * 60 * 1000;
          break;
        default:
          break;
      }
      filtered = filtered.filter(
        (logI) => Number(logI.updateTimestamp) > minTimestamp
      );
    }
    console.log(filtered);
    setFilteredLogs(filtered);
  };

  const clearFilters = () => {
    setActionType(null);
    setActionTypePhaseTwo(null);
    setUserType([]);
    setTimeFrame(null);
    setFilteredLogs(logs);
    setClicked(null);
  };

  function targetName(logData) {
    return (
      logData.member?.username ||
      logData.channel?.name ||
      logData.newChannel?.name ||
      logData.emoji?.name ||
      logData.newEmoji?.name ||
      logData.event?.name ||
      logData.newEvent?.name ||
      logData.newMember?.username ||
      logData.newName ||
      logData.invite?.url ||
      logData.message?.content.slice(0, 10) ||
      logData.role?.name ||
      logData.newRole?.name ||
      logData.stage?.topic ||
      logData.newStage?.topic ||
      logData.sticker?.name ||
      logData.newSticker?.name ||
      logData.thread?.name ||
      logData.newThread?.name ||
      "Unavailable"
    );
  }

  function stickerFormat(type) {
    if (type === 1) {
      return "PNG";
    } else if (type === 2) {
      return "APNG";
    } else if (type === 3) {
      return "Lottie";
    } else if (type === 4) {
      return "GIF";
    } else {
      return "Unknown Type";
    }
  }

  function renderPermissions(permissions) {
    const results = Object.entries(permissions).filter(
      (permission) => permission[1]
    );
    return results.map((permission) => (
      <>
        <br />
        {permission[0].replace(/([a-z])([A-Z])/g, "$1 $2")}
      </>
    ));
  }

  function rowSpanCalculator(array) {
    let rows = 0;
    array.forEach((entity) => {
      const allowed = entity[2].length;
      const denied = entity[3].length;
      if (allowed > denied) {
        rows += allowed;
      } else if (allowed === 0 && denied === 0) {
        rows += 1;
      } else {
        rows += denied;
      }
    });
    return rows;
  }

  function channelCardMaker(channel) {
    let permissionOverwrites = channel.permissions;
    if (!permissionOverwrites.length) {
      return (
        <div className="row justify-content-center">
          <div className="col-md-8">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>No Permissions</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      );
    }
    const roles = permissionOverwrites.filter(
      (overwrite) => overwrite[0] === "role"
    );
    const users = permissionOverwrites.filter(
      (overwrite) => overwrite[0] === "user"
    );
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>Type</th>
                  <th style={{ width: "20%" }}>Name</th>
                  <th>Allowed</th>
                  <th>Denied</th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role, index) => {
                  let maxPermsCount =
                    role[2].length > role[3].length
                      ? role[2].length
                      : role[3].length;
                  const allowed = role[2];
                  const denied = role[3];
                  let components = [];
                  if (!index) {
                    components.push(
                      <tr>
                        <td rowSpan={rowSpanCalculator(roles)}>Roles</td>
                        <td rowSpan={maxPermsCount || 1}>{role[1]}</td>
                        <td>{allowed.length ? allowed[0] : ""}</td>
                        <td>{denied.length ? denied[0] : ""}</td>
                      </tr>
                    );
                    for (let i = 1; i < maxPermsCount; i++) {
                      components.push(
                        <tr>
                          <td>{allowed.length >= i ? allowed[i] : ""}</td>
                          <td>{denied.length >= i ? denied[i] : ""}</td>
                        </tr>
                      );
                    }
                  } else {
                    for (let i = 0; i < maxPermsCount; i++) {
                      if (!i) {
                        components.push(
                          <tr>
                            <td rowSpan={maxPermsCount || 1}>{role[1]}</td>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      } else {
                        components.push(
                          <tr>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      }
                    }
                  }
                  return components;
                })}
                {users.map((user, index) => {
                  let maxPermsCount =
                    user[2].length > user[3].length
                      ? user[2].length
                      : user[3].length;
                  const allowed = user[2];
                  const denied = user[3];
                  let components = [];
                  if (!index) {
                    components.push(
                      <tr>
                        <td rowSpan={rowSpanCalculator(users)}>Users</td>
                        <td rowSpan={maxPermsCount || 1}>{user[1]}</td>
                        <td>{allowed.length ? allowed[0] : ""}</td>
                        <td>{denied.length ? denied[0] : ""}</td>
                      </tr>
                    );
                    for (let i = 1; i < maxPermsCount; i++) {
                      components.push(
                        <tr>
                          <td>{allowed.length >= i ? allowed[i] : ""}</td>
                          <td>{denied.length >= i ? denied[i] : ""}</td>
                        </tr>
                      );
                    }
                  } else {
                    for (let i = 0; i < maxPermsCount; i++) {
                      if (!i) {
                        components.push(
                          <tr>
                            <td rowSpan={maxPermsCount || 1}>{user[1]}</td>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      } else {
                        components.push(
                          <tr>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      }
                    }
                  }
                  return components;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function getDataDetails(log) {
    switch (log.data.phaseTwo) {
      case "Update Server Name":
        return (
          <>
            Old Name: {log.data.oldName}
            <br />
            New Name: {log.data.newName}
          </>
        );
      case "Onboarding Created":
      case "Onboarding Updated":
      case "Invite Created":
        return (
          <>
            URL: {log.data.invite.url}
            <br />
            Inviter: {log.data.invite.inviter}
            <br />
            Expires:{" "}
            {new Date(Number(log.data.invite.expiresTimestamp)).toUTCString() ||
              "Never Expires"}
            <br />
            Max Uses: {log.data.invite.maxUses || "Unlimited"}
            <br />
            Max Age:{" "}
            {log.data.invite.maxAge / (60 * 60 * 24) + " days" ||
              "Never Expires"}
            <br />
          </>
        );
      case "Invite Deleted":
        return (
          <>
            URL: {log.data.invite.url}
            <br />
            Inviter: {log.data.invite.inviter}
            <br />
            Max Uses: {log.data.invite.maxUses || "Unlimited"}
            <br />
            Max Age:{" "}
            {log.data.invite.maxAge / (60 * 60 * 24) + " days" ||
              "Never Expires"}
            <br />
          </>
        );
      case "Emoji Created":
        return (
          <>
            Name: {log.data.emoji.name}
            <br />
            Animated: {log.data.emoji.animated ? "Yes" : "No"}
            <br />
            Emoji:
            <br />
            <img src={log.data.emoji.url} alt={log.data.emoji.name} />
            <br />
          </>
        );
      case "Emoji Updated":
        return (
          <>
            Old Name: {log.data.oldEmoji.name}
            <br />
            Animated (Old): {log.data.oldEmoji.animated ? "Yes" : "No"}
            <br />
            Old Emoji:
            <br />
            <img src={log.data.oldEmoji.url} alt={log.data.oldEmoji.name} />
            <br />
            New Name: {log.data.newEmoji.name}
            <br />
            Animated (New): {log.data.newEmoji.animated ? "Yes" : "No"}
            <br />
            New Emoji:
            <br />
            <img src={log.data.newEmoji.url} alt={log.data.newEmoji.name} />
            <br />
          </>
        );
      case "Emoji Deleted":
        return (
          <>
            Name: {log.data.emoji.name}
            <br />
            Animated: {log.data.emoji.animated ? "Yes" : "No"}
            <br />
            Emoji:
            <br />
            <img src={log.data.emoji.url} alt={log.data.emoji.name} />
            <br />
          </>
        );
      case "Sticker Created":
        return (
          <>
            Name: {log.data.sticker.name}
            <br />
            Format: {stickerFormat(log.data.sticker.format)}
            <br />
            Sticker:
            <br />
            <img
              className="w-50"
              src={log.data.sticker.url}
              alt={log.data.sticker.name}
            />
            <br />
          </>
        );
      case "Sticker Updated":
        return (
          <>
            Old Name: {log.data.oldSticker.name}
            <br />
            Old Format: {stickerFormat(log.data.oldSticker.format)}
            <br />
            Old Sticker:
            <br />
            <img
              className="w-50"
              src={log.data.oldSticker.url}
              alt={log.data.oldSticker.name}
            />
            <br />
            New Name: {log.data.newSticker.name}
            <br />
            New Format: {stickerFormat(log.data.newSticker.format)}
            <br />
            New Sticker:
            <br />
            <img
              className="w-50"
              src={log.data.newSticker.url}
              alt={log.data.newSticker.name}
            />
            <br />
          </>
        );
      case "Sticker Deleted":
        return (
          <>
            Name: {log.data.sticker.name}
            <br />
            Format: {stickerFormat(log.data.sticker.format)}
            <br />
            Sticker:
            <br />
            <img
              className="w-50"
              src={log.data.sticker.url}
              alt={log.data.sticker.name}
            />
            <br />
          </>
        );
      case "Role Addition":
        return (
          <>
            Name: {log.data.role.name}
            <br />
          </>
        );
      case "Role Deletion":
        return (
          <>
            Name: {log.data.role.name}
            <br />
            <br />
            Permissions:
            {renderPermissions(log.data.role.permissions)}
          </>
        );
      case "Role Permission Changes":
        return (
          <>
            Old Permissions:
            {renderPermissions(log.data.newRole.permissions)}
            <br />
            <br />
            New Permissions:
            {renderPermissions(log.data.oldRole.permissions)}
          </>
        );
      case "Channel Addition":
        return (
          <>
            Channel Name: {log.data.channel.name}
            <br />
            Type: {log.data.channel.type}
            <br/>
          </>
        );
      case "Channel Deletion":
        return (
          <>
            Channel Name: {log.data.channel.name}
            <br />
            Type: {log.data.channel.type}
            <br/>
            {channelCardMaker(log.data.channel)}
          </>
        );
      case "Channel Permission Changes":
        return (
          <>
            Channel Name: {log.data.newChannel.name}
            Channel Type: {log.data.newChannel.type}
            <br />
            <br />
            Old Permissions: {channelCardMaker(log.data.oldChannel)}
            <br />
            <br />
            New Permissions: {channelCardMaker(log.data.newChannel)}
          </>
        );
      case "Kicked User":
        return (
          <>
            Name: {log.data.member.username}
            <br />
            Reason: {log.data.member.reason || "No reason provided"}
          </>
        );
      case "Banned User":
        return (
          <>
            Name: {log.data.member.username}
            <br />
            Reason: {log.data.member.reason || "No reason provided"}
          </>
        );
        case "Remove Ban":
          return (
            <>
              Name: {log.data.member.username}
              <br />
              Reason: {log.data.member.reason || "No reason provided"}
            </>
          );
      case "Timed Out User":
        return (
          <>
            Name: {log.data.member.username}
            <br />
            Timeout Ends On:{" "}
            {new Date(Number(log.data.member.timeOutEndsOn)).toUTCString()}
          </>
        );
      case "Remove Timeout":
        return (
          <>
            Name: {log.data.member.username}
            <br />
          </>
        )
      case "Message Deleted":
        return (
          <>
            Author: {log.data.message.messageAuthor}
            <br />
            Channel: {log.data.message.channelName}
            <br />
            Content: {log.data.message.content}
          </>
        );
      case "Message Pinned":
        return (
          <>
            Author: {log.data.message.messageAuthor}
            <br />
            Channel: {log.data.message.channelName}
            <br />
            Content: {log.data.message.content}
          </>
        );
      case "Thread Created":
        return (
          <>
            Name: {log.data.thread.name}
            <br />
            Locked: {log.data.thread.locked ? "Yes" : "No"}
            <br />
            Auto Archive: {log.data.thread.autoArchive + " mins"}
          </>
        );
      case "Thread Deleted":
        return (
          <>
            Name: {log.data.thread.name}
            <br />
            Locked: {log.data.thread.locked ? "Yes" : "No"}
            <br />
            Auto Archive: {log.data.thread.autoArchive + " mins"}
            <br />
            Message Count: {log.data.thread.messageCount}
            <br />
            Member Count: {log.data.thread.memberCount}
          </>
        );
      case "Thread Updated":
        return (
          <>
            Old Thread:
            <br />
            Name: {log.data.oldThread.name}
            <br />
            Locked: {log.data.oldThread.locked ? "Yes" : "No"}
            <br />
            Auto Archive: {log.data.oldThread.autoArchive + " mins"}
            <br />
            <br />
            New Thread:
            <br />
            Name: {log.data.newThread.name}
            <br />
            Locked: {log.data.newThread.locked ? "Yes" : "No"}
            <br />
            Auto Archive: {log.data.newThread.autoArchive + " mins"}
          </>
        );
      case "Integration Created":
      case "Integration Deleted":
      case "Integration Updated":
      case "Event Created":
        return (
          <>
            Name: {log.data.event.name}
            <br />
            Creator: {log.data.event.creator}
            <br />
            Description: {log.data.event.description || "No Description"}
            <br />
            Starts:{" "}
            {new Date(Number(log.data.event.startTimestamp)).toUTCString()}
            <br />
            Ends: {new Date(Number(log.data.event.endTimestamp)).toUTCString()}
          </>
        );
      case "Event Deleted":
        return (
          <>
            Name: {log.data.event.name}
            <br />
            Creator: {log.data.event.creator}
            <br />
            Description: {log.data.event.description || "No Description"}
            <br />
            Starts:{" "}
            {new Date(Number(log.data.event.startTimestamp)).toUTCString()}
            <br />
            Ends: {new Date(Number(log.data.event.endTimestamp)).toUTCString()}
          </>
        );
      case "Event Updated":
        return (
          <>
            Old Name: {log.data.oldEvent.name}
            <br />
            Old Creator: {log.data.oldEvent.creator}
            <br />
            Old Description: {log.data.oldEvent.description || "No Description"}
            <br />
            Old Starts:{" "}
            {new Date(Number(log.data.oldEvent.startTimestamp)).toUTCString()}
            <br />
            Old Ends:{" "}
            {new Date(Number(log.data.oldEvent.endTimestamp)).toUTCString()}
            <br />
            <br />
            New Name: {log.data.newEvent.name}
            <br />
            New Creator: {log.data.newEvent.creator}
            <br />
            New Description: {log.data.newEvent.description || "No Description"}
            <br />
            New Starts:{" "}
            {new Date(Number(log.data.newEvent.startTimestamp)).toUTCString()}
            <br />
            New Ends:{" "}
            {new Date(Number(log.data.newEvent.endTimestamp)).toUTCString()}
          </>
        );
      case "Stage Created":
        return (
          <>
            Topic: {log.data.stage.topic}
            <br />
            Channel: {log.data.stage.channelName}
            <br />
            Event Name: {log.data.stage.eventName || "No event"}
          </>
        );
      case "Stage Deleted":
        return (
          <>
            Topic: {log.data.stage.topic}
            <br />
            Channel: {log.data.stage.channelName}
            <br />
            Event Name: {log.data.stage.eventName || "No event"}
          </>
        );
      case "Stage Updated":
        return (
          <>
            New Topic: {log.data.newStage.topic}
            <br />
            New Channel: {log.data.newStage.channelName}
            <br />
            New Event Name: {log.data.newStage.eventName || "No event"}
            <br />
            <br />
            Old Topic: {log.data.oldStage.topic}
            <br />
            Old Channel: {log.data.oldStage.channelName}
            <br />
            Old Event Name: {log.data.oldStage.eventName || "No event"}
          </>
        );
      case "Nickname Change":
        return (
          <>
            Username: {log.data.oldMember.username}
            <br />
            <br />
            New Nickname: {log.data.newMember.nickname}
            <br />
            Old Nickname: {log.data.oldMember.nickname}
          </>
        );
      case "Role Change":
        return (
          <>
            Username: {log.data.newMember.username || "Unavailable"}
            <br />
            <br />
            New Roles:{" "}
            {log.data.newRoles.map((roleName) => (
              <>
                <br />
                {roleName}
              </>
            ))}
            <br />
            <br />
            Old Roles:{" "}
            {log.data.oldRoles.map((roleName) => (
              <>
                <br />
                {roleName}
              </>
            ))}
          </>
        );
      default:
        return <>No Data Available</>;
    }
  }

  return (
    <div className="container mt-3">
      <div className="row justify-content-center mt-3 mb-4">
        <h2>Simply Better Audit Logs</h2>
      </div>
      <div className="row mb-3">
        <div className="col">
          <div className="dropdown">
            <button
              className="btn btn-primary dropdown-toggle"
              type="button"
              id="actionTypeDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {actionType ? actionType : "Select Action Type"}
            </button>
            <ul className="dropdown-menu" aria-labelledby="actionTypeDropdown">
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() => handleActionTypeChange("Server Changes")}
                >
                  Server Changes
                </div>
              </li>
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() =>
                    handleActionTypeChange("Role Additions and Deletions")
                  }
                >
                  Role Additions and Deletions
                </div>
              </li>
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() =>
                    handleActionTypeChange("Role Permission Changes")
                  }
                >
                  Role Permission Changes
                </div>
              </li>
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() =>
                    handleActionTypeChange("Channel Additions and Deletions")
                  }
                >
                  Channel Additions and Deletions
                </div>
              </li>
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() =>
                    handleActionTypeChange("Channel Permission Changes")
                  }
                >
                  Channel Permissions Changes
                </div>
              </li>
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() => handleActionTypeChange("Punishments")}
                >
                  Punishments
                </div>
              </li>
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() => handleActionTypeChange("Messages Changes")}
                >
                  Messages Changes
                </div>
              </li>
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() => handleActionTypeChange("Thread Changes")}
                >
                  Thread Changes
                </div>
              </li>
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() => handleActionTypeChange("Integration Changes")}
                >
                  Integration Changes
                </div>
              </li>
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() => handleActionTypeChange("Stages & Events")}
                >
                  Stages & Events
                </div>
              </li>
              <li>
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  role="button"
                  onClick={() => handleActionTypeChange("Member Changes")}
                >
                  Member Changes
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="dropdown">
            <button
              className="btn btn-primary dropdown-toggle"
              type="button"
              id="userTypeDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Select User Type
            </button>
            <ul className="dropdown-menu" aria-labelledby="userTypeDropdown">
              <li>
                <div
                  style={
                    !userType.includes("Bots")
                      ? {
                          backgroundColor: "white",
                          padding: "5px 10px",
                          transition: "background-color 0.3s ease",
                        }
                      : {
                          backgroundColor: "#C0C0C0",
                          padding: "5px 10px",
                          transition: "background-color 0.3s ease",
                        }
                  }
                  onMouseEnter={(e) => {
                    if (!userType.includes("Bots"))
                      e.currentTarget.style.backgroundColor = "#f0f0f0";
                  }}
                  onMouseLeave={(e) => {
                    if (!userType.includes("Bots"))
                      e.currentTarget.style.backgroundColor = "white";
                  }}
                  role="button"
                  onClick={() => handleUserTypeChange("Bots")}
                >
                  Bots
                </div>
              </li>
              <li>
                <div
                  role="button"
                  style={
                    !userType.includes("Admins")
                      ? {
                          backgroundColor: "white",
                          padding: "5px 10px",
                          transition: "background-color 0.3s ease",
                        }
                      : {
                          backgroundColor: "#C0C0C0",
                          padding: "5px 10px",
                          transition: "background-color 0.3s ease",
                        }
                  }
                  onMouseEnter={(e) => {
                    if (!userType.includes("Admins"))
                      e.currentTarget.style.backgroundColor = "#f0f0f0";
                  }}
                  onMouseLeave={(e) => {
                    if (!userType.includes("Admins"))
                      e.currentTarget.style.backgroundColor = "white";
                  }}
                  onClick={() => handleUserTypeChange("Admins")}
                >
                  Users with Administrator Permissions
                </div>
              </li>
              <li>
                <div
                  role="button"
                  style={
                    !userType.includes("Moderators")
                      ? {
                          backgroundColor: "white",
                          padding: "5px 10px",
                          transition: "background-color 0.3s ease",
                        }
                      : {
                          backgroundColor: "#C0C0C0",
                          padding: "5px 10px",
                          transition: "background-color 0.3s ease",
                        }
                  }
                  onMouseEnter={(e) => {
                    if (!userType.includes("Moderators"))
                      e.currentTarget.style.backgroundColor = "#f0f0f0";
                  }}
                  onMouseLeave={(e) => {
                    if (!userType.includes("Moderators"))
                      e.currentTarget.style.backgroundColor = "white";
                  }}
                  onClick={() => handleUserTypeChange("Moderators")}
                >
                  Users with Moderator Permissions
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col">
          <div className="dropdown">
            <button
              className="btn btn-primary dropdown-toggle"
              type="button"
              id="timeFrameDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {timeFrame ? timeFrame : "Select Time Frame"}
            </button>
            <ul className="dropdown-menu" aria-labelledby="timeFrameDropdown">
              <li>
                <div
                  role="button"
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  onClick={() => handleTimeFrameChange("1hour")}
                >
                  1 Hour
                </div>
              </li>
              <li>
                <div
                  role="button"
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  onClick={() => handleTimeFrameChange("24hours")}
                >
                  24 Hours
                </div>
              </li>
              <li>
                <div
                  role="button"
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  onClick={() => handleTimeFrameChange("1week")}
                >
                  1 Week
                </div>
              </li>
              <li>
                <div
                  role="button"
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  onClick={() => handleTimeFrameChange("1month")}
                >
                  1 Month
                </div>
              </li>
              <li>
                <div
                  role="button"
                  style={{
                    backgroundColor: "white",
                    padding: "5px 10px",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#f0f0f0")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "white")
                  }
                  onClick={() => handleTimeFrameChange("6months")}
                >
                  6 Months
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col">
          <button className="btn btn-secondary" onClick={clearFilters}>
            Remove Filters
          </button>
        </div>
      </div>
      <div
        className="row mb-3"
        style={{ visibility: actionType ? "visible" : "hidden" }}
      >
        <div className="col">
          <div className="dropdown">
            <button
              className="btn btn-primary dropdown-toggle"
              type="button"
              id="actionTypeTwoDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {actionTypePhaseTwo ? actionTypePhaseTwo : "Select Action Type"}
            </button>
            <ul className="dropdown-menu" aria-labelledby="actionTypeDropdown">
              {actionType && phaseTwoOptions[actionType].length ? (
                phaseTwoOptions[actionType].map((el) => {
                  return (
                    <li>
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "5px 10px",
                          transition: "background-color 0.3s ease",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "#f0f0f0")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "white")
                        }
                        role="button"
                        onClick={() => setActionTypePhaseTwo(el)}
                      >
                        {el}
                      </div>
                    </li>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col table-responsive">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Executor</th>
                <th>Target</th>
                <th>Action</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {filteredLogs.map((log, index) =>
                clicked !== index ? (
                  <tr
                    onClick={() =>
                      clicked === index ? setClicked(null) : setClicked(index)
                    }
                    key={index}
                  >
                    <td>{log.executor?.username ?  log.executor.username : "No Data"}</td>
                    <td>{targetName(log.data)}</td>
                    <td>{log.data.phaseOne + " - " + log.data.phaseTwo}</td>
                    <td>
                      {new Date(Number(log.updateTimestamp)).toUTCString()}
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr
                      className="table-info"
                      onClick={() =>
                        clicked === index ? setClicked(null) : setClicked(index)
                      }
                      key={index}
                    >
                      <td>{log.executor.username}</td>
                      <td>{targetName(log.data)}</td>
                      <td>{log.data.phaseOne + " - " + log.data.phaseTwo}</td>
                      <td>
                        {new Date(Number(log.updateTimestamp)).toUTCString()}
                      </td>
                    </tr>
                    <tr
                      className="table-info"
                      style={{ height: "200px" }}
                      onClick={() =>
                        clicked === index ? setClicked(null) : setClicked(index)
                      }
                      key={index}
                    >
                      <td colSpan={4}>
                        <div>{getDataDetails(log)}</div>
                      </td>
                    </tr>
                  </>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Logs;

import { BsChevronRight, BsChevronDown } from "react-icons/bs";

const RoleMaker = ({ role, showRolePerms, handleRoleNameClick }) => {
  function renderPermissionsTable(rolePerms) {
    const permissionsArray = rolePerms;
    if (permissionsArray.length) {
      return (
        <div className="row justify-content-center">
          <div className="col-md-8">
            <table className="table table-bordered">
              <tbody>
                {permissionsArray.map((permission, index) => (
                  <tr key={index}>
                    <td>{permission}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row justify-content-center">
          <div className="col-md-8">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>No Permissions</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="mt-2">
      <h5 key={role[0]} className="card-title mt-2 flex-row">
        <span className="text-secondary" onClick={handleRoleNameClick}>
          {role[0]}
        </span>
        <span className="text-secondary"> </span>
        <span className="text-secondary">
          {showRolePerms.includes(role[0]) ? (
            <BsChevronDown />
          ) : (
            <BsChevronRight />
          )}
        </span>
      </h5>
      {showRolePerms.includes(role[0]) ? (
        renderPermissionsTable(role[1])
      ) : (
        <></>
      )}
    </div>
  );
};

export default RoleMaker;

import { Link } from "react-router-dom";

import HomePageTop from "../components/HomePageTop";
import HomePageMenu from "../components/HomePageMenu";

const HomePage = () => {
  return (
    <div className="container mt-3">
      <HomePageTop />

      <HomePageMenu
        title="Snapshots"
        description="View all the snapshots taken for the server. This includes the live snapshot, daily snapshots, manual snapshots and the snapshots made due to change in settings."
      />
      <Link to="/snapshots">
        <button className="btn btn-primary btn-sm">Snapshots</button>
      </Link>

      <HomePageMenu
        title="Changes"
        description="View all the security related changes made in the roles and channels of the server. This gives out a log of creation and deletion of roles and channels and changes in their permissions."
      />
      <Link to="/changes">
        <button className="btn btn-primary btn-sm">Changes</button>
      </Link>

      <HomePageMenu
        title="Bots"
        description="View the details about bots and their permissions. Gets you a list of bots present in the server along with their permissions."
      />
      <Link to="/bots">
        <button className="btn btn-primary btn-sm">Bots</button>
      </Link>

      <HomePageMenu
        title="Comparisons"
        description="Compare two states of server settingsto find differences in settings between two server permission states."
      />
      <Link to="/compare">
        <button className="btn btn-primary btn-sm">Compare</button>
      </Link>

      <HomePageMenu
        title="Audit Logs"
        description="Imagine discord audit logs but way better."
      />
      <Link to="/logs">
        <button className="btn btn-primary btn-sm">Audit Logs</button>
      </Link>
    </div>
  );
};

export default HomePage;

const SnapShotPageIntro = ({ select }) => {
  return (
    <div>
      <div className="mt-3">
        <h2>Choose the type of snapshot you want to view.</h2>
      </div>
      <div className="options">
        <div className="row mt-5">
          <div className="col-md-6">
            <h4>Current</h4>
            <p>
              View the permissions assigned to each role and channel in the
              server at moment.
            </p>
            <button
              className="btn btn-primary"
              onClick={() => select("current")}
            >
              Current
            </button>
          </div>
          <div className="col-md-6">
            <h4>Manual</h4>
            <p>
              View the permissions assigned to each role and channel in the
              server when the manual snapshots were taken.
            </p>
            <button
              className="btn btn-primary"
              onClick={() => select("manual")}
            >
              Manual
            </button>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <h4>Periodic</h4>
            <p>
              View the recorded permissions assigned to each role and channel in
              the server.
            </p>
            <button
              className="btn btn-primary"
              onClick={() => select("periodic")}
            >
              Periodic
            </button>
          </div>
          <div className="col-md-6">
            <h4>Changes</h4>
            <p>
              View the permissions assigned to each role and channel in the
              server when any changes was made.
            </p>
            <button
              className="btn btn-primary"
              onClick={() => select("changes")}
            >
              Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnapShotPageIntro;

import { useState } from "react";

import RoleMaker from "./RoleCardMaker";
import ChannelMaker from "./ChannelCardMaker";

const CurrentSnapshot = ({ logsData }) => {
  const [showChannels, setShowChannels] = useState(false);
  const [showRoles, setShowRoles] = useState(false);
  const [showRolePerms, setShowRolePerms] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);

  function handleChannelClick() {
    setShowChannels(!showChannels);
    setSelectedChannels([]);
  }

  function handleRoleClick() {
    setShowRoles(!showRoles);
  }

  function handleRoleNameClick(event) {
    const roleName = event.target.innerHTML;
    if (showRolePerms.includes(roleName)) {
      setShowRolePerms(showRolePerms.filter((role) => role !== roleName));
    } else {
      setShowRolePerms([...showRolePerms, roleName]);
    }
  }

  function handleCollapseAll() {
    setShowChannels(false);
    setShowRoles(false);
    setShowRolePerms([]);
    setSelectedChannels([]);
  }

  function handleExpandAll() {
    setShowChannels(true);
    setShowRoles(true);
    setShowRolePerms(logsData.data?.roleProfile?.map((role) => role[0]));
    setSelectedChannels(
      logsData.data?.channelProfile?.map((channel) => channel[0])
    );
  }

  return (
    <div className="container">
      <div className="row justify-content-center mt-3 mb-4">
        <h2>Current Snapshot</h2>
      </div>
      <div className="row justify-content-center">
        <div className="d-flex gap-2">
          <div>
            {logsData?.data ? (
              <button
                className="btn btn-sm btn-primary"
                onClick={handleCollapseAll}
              >
                Collapse All
              </button>
            ) : (
              <button
                className="btn btn-sm btn-primary"
                onClick={handleCollapseAll}
                disabled
              >
                Collapse All
              </button>
            )}
          </div>
          <div>
            {logsData?.data ? (
              <button
                className="btn btn-sm btn-primary"
                onClick={handleExpandAll}
              >
                Expand All
              </button>
            ) : (
              <button
                className="btn btn-sm btn-primary"
                onClick={handleExpandAll}
                disabled
              >
                Expand All
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="card mt-3" style={{ cursor: "pointer" }}>
        <h3
          className="card-header bg-white text-dark"
          onClick={handleChannelClick}
        >
          Channels
        </h3>
        <div className={showChannels ? "" : "collapse"}>
          {logsData?.data?.channelProfile?.map((channel) => {
            return (
              <ChannelMaker
                channel={channel}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
              />
            );
          })}
        </div>
      </div>
      <div className="card mt-3 mb-3" style={{ cursor: "pointer" }}>
        <h3
          className="card-header bg-white text-dark"
          onClick={handleRoleClick}
        >
          Roles
        </h3>
        <div className={showRoles ? "" : "collapse"}>
          {logsData?.data?.roleProfile?.map((role) => {
            return (
              <RoleMaker
                role={role}
                showRolePerms={showRolePerms}
                handleRoleNameClick={handleRoleNameClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CurrentSnapshot;

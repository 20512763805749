const Dropdown = ({ setChosenSnapshot, data }) => {
  const convertToDateTimeString = (timestamp) => {
    const dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    };

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userDateTime = new Date(Number(timestamp)).toLocaleString(undefined, {
      ...dateOptions,
      ...timeOptions,
      timeZone: userTimeZone,
    });

    return userDateTime;
  };

  function handleOptionSelect(event) {
    const stringSelected = event.target.value;
    const selectedOption = data.find(
      (el) => el.updateTimestamp === stringSelected
    );
    setChosenSnapshot(selectedOption);
  }

  return (
    <div className="dropdown mt-5">
      <select
        className="form-select"
        aria-label="Select Snapshot"
        onChange={handleOptionSelect}
      >
        <option value="" disabled selected>
          Pick a snapshot!
        </option>
        {data ? (
          data.map((botsData, index) => (
            <option key={index} value={botsData.updateTimestamp}>
              {convertToDateTimeString(botsData.updateTimestamp)}
            </option>
          ))
        ) : (
          <></>
        )}
      </select>
    </div>
  );
};

export default Dropdown;

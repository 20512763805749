import {
  BsChevronRight,
  BsChevronDown,
  BsMegaphone,
  BsList,
} from "react-icons/bs";

const ChannelMaker = ({ channel, selectedChannels, setSelectedChannels }) => {
  function handleChannelNameClick(event) {
    const channelName = event.target.innerHTML;
    if (selectedChannels.includes(channelName)) {
      setSelectedChannels(
        selectedChannels.filter((channel) => channel !== channelName)
      );
    } else {
      setSelectedChannels([...selectedChannels, channelName]);
    }
  }

  function rowSpanCalculator(array) {
    let rows = 0;
    array.forEach((entity) => {
      const allowed = entity[2].length;
      const denied = entity[3].length;
      if (allowed > denied) {
        rows += allowed;
      } else if (allowed === 0 && denied === 0) {
        rows += 1;
      } else {
        rows += denied;
      }
    });
    return rows;
  }

  function renderChannelPermissions(permissionOverwrites) {
    if (!permissionOverwrites.length) {
      return (
        <div className="row justify-content-center">
          <div className="col-md-8">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>No Permissions</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      );
    }
    const roles = permissionOverwrites.filter(
      (overwrite) => overwrite[0] === "role"
    );
    const users = permissionOverwrites.filter(
      (overwrite) => overwrite[0] === "user"
    );
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>Type</th>
                  <th style={{ width: "20%" }}>Name</th>
                  <th>Allowed</th>
                  <th>Denied</th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role, index) => {
                  let maxPermsCount =
                    role[2].length > role[3].length
                      ? role[2].length
                      : role[3].length;
                  const allowed = role[2];
                  const denied = role[3];
                  let components = [];
                  if (!index) {
                    components.push(
                      <tr>
                        <td rowSpan={rowSpanCalculator(roles)}>Roles</td>
                        <td rowSpan={maxPermsCount || 1}>{role[1]}</td>
                        <td>{allowed.length ? allowed[0] : ""}</td>
                        <td>{denied.length ? denied[0] : ""}</td>
                      </tr>
                    );
                    for (let i = 1; i < maxPermsCount; i++) {
                      components.push(
                        <tr>
                          <td>{allowed.length >= i ? allowed[i] : ""}</td>
                          <td>{denied.length >= i ? denied[i] : ""}</td>
                        </tr>
                      );
                    }
                  } else {
                    for (let i = 0; i < maxPermsCount; i++) {
                      if (!i) {
                        components.push(
                          <tr>
                            <td rowSpan={maxPermsCount || 1}>{role[1]}</td>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      } else {
                        components.push(
                          <tr>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      }
                    }
                  }
                  return components;
                })}
                {users.map((user, index) => {
                  let maxPermsCount =
                    user[2].length > user[3].length
                      ? user[2].length
                      : user[3].length;
                  const allowed = user[2];
                  const denied = user[3];
                  let components = [];
                  if (!index) {
                    components.push(
                      <tr>
                        <td rowSpan={rowSpanCalculator(users)}>Users</td>
                        <td rowSpan={maxPermsCount || 1}>{user[1]}</td>
                        <td>{allowed.length ? allowed[0] : ""}</td>
                        <td>{denied.length ? denied[0] : ""}</td>
                      </tr>
                    );
                    for (let i = 1; i < maxPermsCount; i++) {
                      components.push(
                        <tr>
                          <td>{allowed.length >= i ? allowed[i] : ""}</td>
                          <td>{denied.length >= i ? denied[i] : ""}</td>
                        </tr>
                      );
                    }
                  } else {
                    for (let i = 0; i < maxPermsCount; i++) {
                      if (!i) {
                        components.push(
                          <tr>
                            <td rowSpan={maxPermsCount || 1}>{user[1]}</td>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      } else {
                        components.push(
                          <tr>
                            <td>{allowed.length >= i ? allowed[i] : ""}</td>
                            <td>{denied.length >= i ? denied[i] : ""}</td>
                          </tr>
                        );
                      }
                    }
                  }
                  return components;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function channelIcon(type) {
    if (type === "category") {
      return <BsList />;
    } else if (type === "text") {
      return "#";
    } else if (type === "voice") {
      return <BsMegaphone />;
    } else return "";
  }

  return (
    <div className="mt-2">
      <h5 key={channel[0]} className="card-title mt-2">
        <span className="text-secondary">{channelIcon(channel[1])}</span>
        <span className="text-secondary"> </span>
        <span className="text-secondary" onClick={handleChannelNameClick}>
          {channel[0]}
        </span>
        <span className="text-secondary"> </span>
        <span className="text-secondary">
          {selectedChannels.includes(channel[0]) ? (
            <BsChevronDown />
          ) : (
            <BsChevronRight />
          )}
        </span>
        {selectedChannels.includes(channel[0]) ? (
          renderChannelPermissions(channel[2])
        ) : (
          <></>
        )}
      </h5>
    </div>
  );
};

export default ChannelMaker;

const HomePageTop = () => {
  return (
    <div className="row">
      <div className="col">
        <h1>Welcome to ST6 Discord Server Security Portal</h1>
        <h3>
          This portal allows you to monitor the permissions of roles and
          channels in real-time for your Discord server.
        </h3>
      </div>
    </div>
  );
};

export default HomePageTop;

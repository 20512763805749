import { useEffect, useState } from "react";
import Dropdown from "../components/BotsDropdown.jsx";
import Botscard from "../components/BotsCard.jsx";

const environment = process.env.NODE_ENV;

const BotsPage = () => {
  const [data, setData] = useState({});
  const [chosenSnapshot, setChosenSnapshot] = useState(null);

  async function fetchUrl(url) {
    const response = await fetch(url);
    const result = await response.json();
    setData(result);
  }

  useEffect(() => {
    fetchUrl(
      environment === "production"
        ? "https://api.watcher.humblefool13.dev/bots/"
        : "http://localhost:3001/bots/"
    );
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center mt-3 mb-4">
        <h2>Bots Data</h2>
      </div>
      {data && (
        <Dropdown setChosenSnapshot={setChosenSnapshot} data={data.data} />
      )}
      {chosenSnapshot ? <Botscard snapshot={chosenSnapshot.data[0]} /> : <></>}
    </div>
  );
};

export default BotsPage;

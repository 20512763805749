import { useEffect, useState } from "react";

import RoleMaker from "./RoleCardMaker";
import ChannelMaker from "./ChannelCardMaker";
import Dropdown from "./DropdownManuals";

const ManualSnapshot = ({ logsData }) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [chosenSnapshot, setChosenSnapshot] = useState(null);
  const [showChannels, setShowChannels] = useState(false);
  const [showRoles, setShowRoles] = useState(false);
  const [showRolePerms, setShowRolePerms] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);

  function handleChannelClick() {
    setShowChannels(!showChannels);
    setSelectedChannels([]);
  }

  function handleRoleClick() {
    setShowRoles(!showRoles);
  }

  function handleRoleNameClick(event) {
    const roleName = event.target.innerHTML;
    if (showRolePerms.includes(roleName)) {
      setShowRolePerms(showRolePerms.filter((role) => role !== roleName));
    } else {
      setShowRolePerms([...showRolePerms, roleName]);
    }
  }

  function handleCollapseAll() {
    setShowChannels(false);
    setShowRoles(false);
    setShowRolePerms([]);
    setSelectedChannels([]);
  }

  function handleExpandAll() {
    setShowChannels(true);
    setShowRoles(true);
    setShowRolePerms(chosenSnapshot.roleProfile.map((role) => role[0]));
    setSelectedChannels(
      chosenSnapshot.channelProfile.map((channel) => channel[0])
    );
  }

  const convertToDateTimeString = (timestamp) => {
    const date = new Date(Number(timestamp));
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
      timeZoneName: "short",
    };
    return date.toLocaleString(undefined, options);
  };

  useEffect(() => {
    setDropdownOptions(
      logsData?.data
        ? logsData?.data.map((snapshot) => [
            `Remarks: ${
              snapshot.remarks ? snapshot.remarks : "None"
            }, Time: ${convertToDateTimeString(snapshot.updateTimestamp)}`,
            snapshot.updateTimestamp,
          ])
        : []
    );
  }, [logsData]);

  return (
    <div className="container">
      <div className="row justify-content-center mt-3 mb-4">
        <h2>Manual Snapshots</h2>
      </div>
      <Dropdown
        dropdownOptions={dropdownOptions}
        setChosenSnapshot={setChosenSnapshot}
        logsData={logsData}
        setShowRoles={setShowRoles}
        setShowChannels={setShowChannels}
      />
      {chosenSnapshot ? (
        <div className="row justify-content-center mt-3 mb-3">
          <div className="d-flex gap-2">
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={handleCollapseAll}
              >
                Collapse All
              </button>
            </div>
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={handleExpandAll}
              >
                Expand All
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {chosenSnapshot ? (
        <div className="card mt-3" style={{ cursor: "pointer" }}>
          <h3
            className="card-header bg-white text-dark"
            onClick={handleChannelClick}
          >
            Channels
          </h3>
          <div className={showChannels ? "" : "collapse"}>
            {chosenSnapshot.channelProfile?.map((channel) => {
              return (
                <ChannelMaker
                  channel={channel}
                  selectedChannels={selectedChannels}
                  setSelectedChannels={setSelectedChannels}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
      {chosenSnapshot ? (
        <div className="card mt-3 mb-3" style={{ cursor: "pointer" }}>
          <h3
            className="card-header bg-white text-dark"
            onClick={handleRoleClick}
          >
            Roles
          </h3>
          <div className={showRoles ? "" : "collapse"}>
            {chosenSnapshot.roleProfile?.map((role) => {
              return (
                <RoleMaker
                  role={role}
                  showRolePerms={showRolePerms}
                  handleRoleNameClick={handleRoleNameClick}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ManualSnapshot;

const Dropdown = ({
  dropdownOptions,
  setChosenSnapshot,
  logsData,
  setShowChannels,
  setShowRoles,
}) => {
  const handleOptionSelect = (event) => {
    const stringSelected = event.target.value;
    const selectedOption = dropdownOptions.find(
      (snapshot) => stringSelected === snapshot[0]
    );
    const selectedTimestamp = selectedOption[1];
    const selectedSnapshot = logsData.data.find(
      (snapshot) => snapshot.updateTimestamp === selectedTimestamp
    );
    setChosenSnapshot(selectedSnapshot);
    setShowChannels(false);
    setShowRoles(false);
  };

  return (
    <div className="dropdown mt-5">
      <select
        className="form-select"
        aria-label="Select Snapshot"
        onChange={handleOptionSelect}
      >
        <option value="" disabled selected>
          Pick the Snapshot to show
        </option>
        {dropdownOptions ? (
          dropdownOptions.map((snapshotData) => (
            <option
              key={snapshotData[1]}
              data-extra={snapshotData[1]}
              value={snapshotData[0]}
            >
              {snapshotData[0]}
            </option>
          ))
        ) : (
          <></>
        )}
      </select>
    </div>
  );
};

export default Dropdown;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

const environment = process.env.NODE_ENV;

const ComparePage = () => {
  const [chosenSnapshots, setChosenSnapshots] = useState([null, null]);
  const [dataL, setDataL] = useState([]);
  const [dataR, setDataR] = useState([]);
  const [snapshots, setSnapshots] = useState([null, null]);
  const [diffs, setDiffs] = useState([null, null]);

  function handleOptionSelectL(event) {
    const stringSelected = event.target.value;
    setChosenSnapshots([stringSelected, chosenSnapshots[1]]);
  }

  function handleOptionSelectR(event) {
    const stringSelected = event.target.value;
    setChosenSnapshots([chosenSnapshots[0], stringSelected]);
  }

  async function handleSnapshotSelectionL(event) {
    const valueOfSelected = event.target.value;
    let theSnapshot = dataL.find((el) => el._id === valueOfSelected);
    if (chosenSnapshots[0] === "changes") {
      theSnapshot = await fetchUrlComparisons(theSnapshot.logID);
    }
    setSnapshots([theSnapshot, snapshots[1]]);
  }

  async function handleSnapshotSelectionR(event) {
    const valueOfSelected = event.target.value;
    let theSnapshot = dataR.find((el) => el._id === valueOfSelected);
    if (chosenSnapshots[1] === "changes") {
      theSnapshot = await fetchUrlComparisons(theSnapshot.logID);
    }
    setSnapshots([snapshots[0], theSnapshot]);
  }

  const convertToDateTimeString = (timestamp) => {
    const dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    };

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userDateTime = new Date(Number(timestamp)).toLocaleString(undefined, {
      ...dateOptions,
      ...timeOptions,
      timeZone: userTimeZone,
    });

    return userDateTime;
  };

  async function fetchUrlComparisons(chosenChange) {
    const response = await fetch(
      environment === "production"
        ? `https://api.watcher.humblefool13.dev/snapshots/changes/${chosenChange}`
        : `http://localhost:3001/snapshots/changes/${chosenChange}`
    );
    const result = await response.json();
    return result.data;
  }

  async function fetchUrl(url, left) {
    const response = await fetch(url);
    const result = await response.json();
    if (left) {
      if (chosenSnapshots[0] === "current") {
        setSnapshots([result?.data, snapshots[1]]);
      }
      setDataL(result?.data);
    } else {
      if (chosenSnapshots[1] === "current") {
        setSnapshots([snapshots[0], result?.data]);
      }
      setDataR(result?.data);
    }
  }

  async function getData(chosenSnapshots) {
    const typeLeft = chosenSnapshots[0];
    const typeRight = chosenSnapshots[1];
    if (typeLeft) {
      switch (typeLeft) {
        case "current":
          fetchUrl(
            environment === "production"
              ? `https://api.watcher.humblefool13.dev/snapshots/current`
              : `http://localhost:3001/snapshots/current`,
            true
          );
          break;
        case "manual":
          fetchUrl(
            environment === "production"
              ? `https://api.watcher.humblefool13.dev/snapshots/manual`
              : `http://localhost:3001/snapshots/manual`,
            true
          );
          break;
        case "periodic":
          fetchUrl(
            environment === "production"
              ? `https://api.watcher.humblefool13.dev/snapshots/production`
              : `http://localhost:3001/snapshots/production`,
            true
          );
          break;
        case "changes":
          fetchUrl(
            environment === "production"
              ? `https://api.watcher.humblefool13.dev/changes`
              : `http://localhost:3001/changes`,
            true
          );
          break;
        default:
          break;
      }
    }
    if (typeRight) {
      switch (typeRight) {
        case "current":
          fetchUrl(
            environment === "production"
              ? `https://api.watcher.humblefool13.dev/snapshots/current`
              : `http://localhost:3001/snapshots/current`,
            false
          );
          break;
        case "manual":
          fetchUrl(
            environment === "production"
              ? `https://api.watcher.humblefool13.dev/snapshots/manual`
              : `http://localhost:3001/snapshots/manual`,
            false
          );
          break;
        case "periodic":
          fetchUrl(
            environment === "production"
              ? `https://api.watcher.humblefool13.dev/snapshots/production`
              : `http://localhost:3001/snapshots/production`,
            false
          );
          break;
        case "changes":
          fetchUrl(
            environment === "production"
              ? `https://api.watcher.humblefool13.dev/changes`
              : `http://localhost:3001/changes`,
            false
          );
          break;
        default:
          break;
      }
    }
  }

  function formatString(dataArray, left) {
    const dataFormat = left ? chosenSnapshots[0] : chosenSnapshots[1];
    let outputStr = "";
    switch (dataFormat) {
      case "manual":
        outputStr = `Remarks: ${
          dataArray.remarks
        }, Time: ${convertToDateTimeString(dataArray.updateTimestamp)}`;
        break;
      case "changes":
        try {
          outputStr = `${dataArray?.executor[0]} ${dataArray.type}d ${dataArray?.target[0]}`;
        } catch (e) {
          outputStr = "0";
        }
        break;
      case "periodic":
        outputStr = `On ${convertToDateTimeString(dataArray.updateTimestamp)}`;
        break;
      default:
        break;
    }
    return outputStr;
  }

  useEffect(() => {
    getData(chosenSnapshots);
  }, [chosenSnapshots]);

  function diffViewer(arrOne, arrTwo) {
    return arrOne.map((el) => {
      if (arrTwo.includes(el)) return el;
      return `${el} *`;
    });
  }

  function rolesSimilar(rolesLeft, rolesRight) {
    let arr = [];
    rolesLeft.forEach((roleArray) => {
      const foundRole = rolesRight.find((el) => el[0] === roleArray[0]);
      if (!foundRole) {
        return arr.push({ roleArray: roleArray, change: "Left" });
      } else if (roleArray[1].toString() !== foundRole[1].toString()) {
        return arr.push({
          roleArrayL: [roleArray[0], diffViewer(roleArray[1], foundRole[1])],
          roleArrayR: [foundRole[0], diffViewer(foundRole[1], roleArray[1])],
          change: "Perms Change",
        });
      }
    });
    rolesRight.forEach((roleArray) => {
      const foundRole = rolesLeft.find((el) => el[0] === roleArray[0]);
      if (!foundRole) {
        return arr.push({ roleArray: roleArray, change: "Right" });
      }
    });
    return arr;
  }

  function channelsSimilar(channelsLeft, channelsRight) {
    if (channelsLeft.toString() === channelsRight.toString()) return [];
    let arr = [];
    channelsLeft.forEach((channelLeft) => {
      const find = channelsRight.find((el) => el[0] === channelLeft[0]);
      if (!find) {
        return arr.push({ change: "Left", channel: channelLeft });
      } else if (
        channelLeft[2].sort().toString() !== find[2].sort().toString()
      ) {
        return arr.push({
          change: "Perms Change",
          channelL: channelLeft,
          channelR: find,
        });
      }
    });
    channelsRight.forEach((channelRight) => {
      const find = channelsLeft.find((el) => el[0] === channelRight[0]);
      if (!find) {
        return arr.push({ change: "Right", channel: channelRight });
      }
    });
    return arr;
  }

  useEffect(() => {
    if (!snapshots[0] || !snapshots[1]) return;
    const rolesLeft = snapshots[0].roleProfile;
    const rolesRight = snapshots[1].roleProfile;
    const diffRoles = rolesSimilar(rolesLeft, rolesRight);
    const channelsLeft = snapshots[0].channelProfile;
    const channelsRight = snapshots[1].channelProfile;
    const diffChannels = channelsSimilar(channelsLeft, channelsRight);
    setDiffs([diffRoles, diffChannels]);
  }, [snapshots]);

  return (
    <div className="container">
      <div className="row justify-content-center mt-3 mb-4">
        <h2>Compare any two snapshots side by side</h2>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="left">
            <div className="dropdown pb-4">
              <select
                className="form-select"
                aria-label="Select Snapshot"
                onChange={handleOptionSelectL}
              >
                <option value="" disabled selected>
                  Pick a snapshot!
                </option>
                <option value="current">Current Snapshot</option>
                <option value="periodic">Periodic Snapshots</option>
                <option value="manual">Manual Snapshots</option>
                <option value="changes">Changes Snapshots</option>
              </select>
            </div>
            {chosenSnapshots[0] && chosenSnapshots[0] !== "current" && (
              <div className="dropdown pb-4">
                <select
                  className="form-select"
                  aria-label="Select Snapshot"
                  onChange={handleSnapshotSelectionL}
                >
                  <option value="" disabled selected>
                    Pick a snapshot!
                  </option>
                  {dataL.length ? (
                    dataL?.map((el, index) => {
                      return (
                        <option key={index} value={el._id}>
                          {formatString(el, true)}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="right">
            <div className="dropdown pb-4">
              <select
                className="form-select"
                aria-label="Select Snapshot"
                onChange={handleOptionSelectR}
              >
                <option value="" disabled selected>
                  Pick a snapshot!
                </option>
                <option value="current">Current Snapshot</option>
                <option value="periodic">Periodic Snapshots</option>
                <option value="manual">Manual Snapshots</option>
                <option value="changes">Changes Snapshots</option>
              </select>
            </div>
            {chosenSnapshots[1] && chosenSnapshots[1] !== "current" && (
              <div className="dropdown pb-4">
                <select
                  className="form-select"
                  aria-label="Select Snapshot"
                  onChange={handleSnapshotSelectionR}
                >
                  <option value="" disabled selected>
                    Pick a snapshot!
                  </option>
                  {dataR.length ? (
                    dataR?.map((el, index) => {
                      return (
                        <option key={index} value={el._id}>
                          {formatString(el, false)}
                        </option>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row justify-content-center mt-3 mb-4">
        {snapshots[0] && snapshots[1] && (
          <>
            <div className="row">
              <h3>Changes</h3>
            </div>
            <div className="border">
              <div className="row p-3">
                <h4>Roles</h4>
              </div>
              {diffs[0] &&
                diffs[0].map((roleData, index) => {
                  if (roleData.change === "Left") {
                    return (
                      <div className="row pt-1 border m-1" key={index}>
                        <div className="col-md-6 pb-3">
                          <h5 className="p-2">{roleData.roleArray[0]}</h5>
                          {roleData.roleArray[1].map((permissionString) => {
                            return (
                              <p
                                className={`p-0 m-0 border ${
                                  permissionString.endsWith(" *")
                                    ? "bg-warning"
                                    : ""
                                }`}
                              >
                                {permissionString.replaceAll(" *", "")}
                              </p>
                            );
                          })}
                        </div>
                        <div className="col-md-6 pb-3"></div>
                      </div>
                    );
                  } else if (roleData.change === "Right") {
                    return (
                      <div className="row pt-1 border m-1" key={index}>
                        <div className="col-md-6 pb-3"></div>
                        <div className="col-md-6 pb-3">
                          <h5 className="p-2">{roleData.roleArray[0]}</h5>
                          {roleData.roleArray[1].map((permissionString) => {
                            return (
                              <p
                                className={`p-0 m-0 border ${
                                  permissionString.endsWith(" *")
                                    ? "bg-warning"
                                    : ""
                                }`}
                              >
                                {permissionString.replaceAll(" *", "")}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="row pt-1 border m-1" key={index}>
                        <div className="col-md-6 pb-3">
                          <h5 className="p-2">{roleData.roleArrayL[0]}</h5>
                          {roleData.roleArrayL[1].map((permissionString) => {
                            return (
                              <p
                                className={`p-0 m-0 border ${
                                  permissionString.endsWith(" *")
                                    ? "bg-warning"
                                    : ""
                                }`}
                              >
                                {permissionString.replaceAll(" *", "")}
                              </p>
                            );
                          })}
                        </div>
                        <div className="col-md-6 pb-3">
                          <h5 className="p-2">{roleData.roleArrayR[0]}</h5>
                          {roleData.roleArrayR[1].map((permissionString) => {
                            return (
                              <p
                                className={`p-0 m-0 border ${
                                  permissionString.endsWith(" *")
                                    ? "bg-warning"
                                    : ""
                                }`}
                              >
                                {permissionString.replaceAll(" *", "")}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
            <div className="row p-3">
              <h4>Channels</h4>
            </div>
            {diffs[1] &&
              diffs[1].map((channel, index) => {
                if (channel.change === "Left") {
                  return (
                    <div className="row pt-1 border m-1" key={index}>
                      <div className="col-md-6 pb-3">
                        <h5 className="p-2">{channel.channel[0]}</h5>
                        <table
                          className="table table-bordered"
                          style={{ width: "90%", margin: "auto" }}
                        >
                          <tr>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Type
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Name
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Allowed
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Denied
                            </th>
                          </tr>
                          {channel.channel.map((overwrite) => {
                            let rows = [];
                            const maxLength = Math.max(
                              overwrite[2]?.length,
                              overwrite[3]?.length
                            );
                            const allowed = overwrite[2];
                            const denied = overwrite[3];
                            for (let i = 0; i < maxLength; i++) {
                              if (i === 0) {
                                rows.push(
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                      rowSpan={maxLength}
                                    >
                                      {overwrite[0] === "role"
                                        ? "Role"
                                        : "User"}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                      rowSpan={maxLength}
                                    >
                                      {overwrite[1]}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {allowed[i] ? allowed[i] : ""}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {denied[i] ? denied[i] : ""}
                                    </td>
                                  </tr>
                                );
                              } else {
                                rows.push(
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {allowed[i] ? allowed[i] : ""}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {denied[i] ? denied[i] : ""}
                                    </td>
                                  </tr>
                                );
                              }
                            }
                            return rows;
                          })}
                        </table>
                      </div>
                      <div className="col-md-6 pb-3"></div>
                    </div>
                  );
                } else if (channel.change === "Right") {
                  return (
                    <div className="row pt-1 border m-1" key={index}>
                      <div className="col-md-6 pb-3"></div>
                      <div className="col-md-6 pb-3">
                        <h5 className="p-2">{channel.channel[0]}</h5>
                        <table
                          className="table table-bordered"
                          style={{ width: "90%", margin: "auto" }}
                        >
                          <tr>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Type
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Name
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Allowed
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Denied
                            </th>
                          </tr>
                          {channel.channel.map((overwrite) => {
                            let rows = [];
                            const maxLength = Math.max(
                              overwrite[2]?.length,
                              overwrite[3]?.length
                            );
                            const allowed = overwrite[2];
                            const denied = overwrite[3];
                            for (let i = 0; i < maxLength; i++) {
                              if (i === 0) {
                                rows.push(
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                      rowSpan={maxLength}
                                    >
                                      {overwrite[0] === "role"
                                        ? "Role"
                                        : "User"}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                      rowSpan={maxLength}
                                    >
                                      {overwrite[1]}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {allowed[i] ? allowed[i] : ""}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {denied[i] ? denied[i] : ""}
                                    </td>
                                  </tr>
                                );
                              } else {
                                rows.push(
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {allowed[i] ? allowed[i] : ""}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {denied[i] ? denied[i] : ""}
                                    </td>
                                  </tr>
                                );
                              }
                            }
                            return rows;
                          })}
                        </table>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="row pt-1 border m-1" key={index}>
                      <div className="col-md-6 pb-3">
                        <h5 className="p-2">{channel.channelL[0]}</h5>
                        <table
                          className="table table-bordered"
                          style={{ width: "90%", margin: "auto" }}
                        >
                          <tr>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Type
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Name
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Allowed
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Denied
                            </th>
                          </tr>
                          {channel.channelL[2].map((overwrite) => {
                            let rows = [];
                            const maxLength = Math.max(
                              overwrite[2]?.length,
                              overwrite[3]?.length
                            );
                            const allowed = overwrite[2];
                            const denied = overwrite[3];
                            for (let i = 0; i < maxLength; i++) {
                              if (i === 0) {
                                rows.push(
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                      rowSpan={maxLength}
                                    >
                                      {overwrite[0] === "role"
                                        ? "Role"
                                        : "User"}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                      rowSpan={maxLength}
                                    >
                                      {overwrite[1]}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {allowed[i] ? allowed[i] : ""}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {denied[i] ? denied[i] : ""}
                                    </td>
                                  </tr>
                                );
                              } else {
                                rows.push(
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {allowed[i] ? allowed[i] : ""}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {denied[i] ? denied[i] : ""}
                                    </td>
                                  </tr>
                                );
                              }
                            }
                            return rows;
                          })}
                        </table>
                      </div>
                      <div className="col-md-6 pb-3">
                        <h5 className="p-2">{channel.channelR[0]}</h5>
                        <table
                          className="table table-bordered"
                          style={{ width: "90%", margin: "auto" }}
                        >
                          <tr>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Type
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Name
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Allowed
                            </th>
                            <th
                              style={{
                                borderLeft: "2px solid #dee2e6",
                                borderRight: "2px solid #dee2e6",
                              }}
                            >
                              Denied
                            </th>
                          </tr>
                          {channel.channelR[2].map((overwrite) => {
                            let rows = [];
                            const maxLength = Math.max(
                              overwrite[2]?.length,
                              overwrite[3]?.length
                            );
                            const allowed = overwrite[2];
                            const denied = overwrite[3];
                            for (let i = 0; i < maxLength; i++) {
                              if (i === 0) {
                                rows.push(
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                      rowSpan={maxLength}
                                    >
                                      {overwrite[0] === "role"
                                        ? "Role"
                                        : "User"}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                      rowSpan={maxLength}
                                    >
                                      {overwrite[1]}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {allowed[i] ? allowed[i] : ""}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {denied[i] ? denied[i] : ""}
                                    </td>
                                  </tr>
                                );
                              } else {
                                rows.push(
                                  <tr>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {allowed[i] ? allowed[i] : ""}
                                    </td>
                                    <td
                                      style={{
                                        borderLeft: "2px solid #dee2e6",
                                        borderRight: "2px solid #dee2e6",
                                      }}
                                    >
                                      {denied[i] ? denied[i] : ""}
                                    </td>
                                  </tr>
                                );
                              }
                            }
                            return rows;
                          })}
                        </table>
                      </div>
                    </div>
                  );
                }
              })}
          </>
        )}
      </div>
    </div>
  );
};

export default ComparePage;

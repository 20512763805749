import { useEffect, useState } from "react";
import DropdownChanges from "../components/DropdownChanges";
import ChannelMaker from "../components/ChannelCardMaker";
import RoleMaker from "../components/RoleCardMaker";
import ChangeDisplay from "../components/ChangeDisplay";

const environment = process.env.NODE_ENV;

const ChangesPage = () => {
  const [logsData, setLogsData] = useState({});
  const [logsDetailData, setLogsDetailData] = useState({});
  const [chosenChange, setChoseChange] = useState("");
  const [showChannels, setShowChannels] = useState(false);
  const [showRoles, setShowRoles] = useState(false);
  const [showRolePerms, setShowRolePerms] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);

  async function fetchUrl(url, isLogsList) {
    const response = await fetch(url);
    const result = await response.json();
    if (isLogsList) {
      setLogsData(result);
    } else {
      setLogsDetailData(result);
    }
  }

  function handleCollapseAll() {
    setShowChannels(false);
    setShowRoles(false);
    setShowRolePerms([]);
    setSelectedChannels([]);
  }

  function handleExpandAll() {
    setShowChannels(true);
    setShowRoles(true);
    setShowRolePerms(logsDetailData?.data?.roleProfile.map((role) => role[0]));
    setSelectedChannels(
      logsDetailData?.data?.channelProfile.map((channel) => channel[0])
    );
  }

  function handleChannelClick() {
    setShowChannels(!showChannels);
    setSelectedChannels([]);
  }

  function handleRoleClick() {
    setShowRoles(!showRoles);
  }

  function handleRoleNameClick(event) {
    const roleName = event.target.innerHTML;
    if (showRolePerms.includes(roleName)) {
      setShowRolePerms(showRolePerms.filter((role) => role !== roleName));
    } else {
      setShowRolePerms([...showRolePerms, roleName]);
    }
  }

  useEffect(() => {
    fetchUrl(
      environment === "production"
        ? "https://api.watcher.humblefool13.dev/changes/"
        : "http://localhost:3001/changes/",
      true
    );
  }, []);

  useEffect(() => {
    fetchUrl(
      environment === "production"
        ? `https://api.watcher.humblefool13.dev/changes/${chosenChange}`
        : `http://localhost:3001/changes/${chosenChange}`,
      false
    );
  }, [chosenChange]);

  return (
    <div className="container">
      <div className="row justify-content-center mt-3 mb-4">
        <h2>Changes Made</h2>
      </div>
      <DropdownChanges
        logsData={logsData}
        setChoseChange={setChoseChange}
        setShowChannels={setShowChannels}
        setShowRoles={setShowRoles}
      />
      {chosenChange ? (
        <ChangeDisplay
          data={logsData.data.find((el) => el.logID === chosenChange)}
        />
      ) : (
        <></>
      )}
      {chosenChange ? (
        <>
          <div className="row justify-content-center mt-3 mb-4">
            <h4>Snapshot At Change</h4>
          </div>
        </>
      ) : (
        <></>
      )}
      {chosenChange ? (
        <div className="row justify-content-center mt-3 mb-3">
          <div className="d-flex gap-2">
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={handleCollapseAll}
              >
                Collapse All
              </button>
            </div>
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={handleExpandAll}
              >
                Expand All
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {chosenChange ? (
        <div className="card mt-3" style={{ cursor: "pointer" }}>
          <h3
            className="card-header bg-white text-dark"
            onClick={handleChannelClick}
          >
            Channels
          </h3>
          <div className={showChannels ? "" : "collapse"}>
            {logsDetailData?.data?.channelProfile?.map((channel) => {
              return (
                <ChannelMaker
                  channel={channel}
                  selectedChannels={selectedChannels}
                  setSelectedChannels={setSelectedChannels}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
      {chosenChange ? (
        <div className="card mt-3 mb-3" style={{ cursor: "pointer" }}>
          <h3
            className="card-header bg-white text-dark"
            onClick={handleRoleClick}
          >
            Roles
          </h3>
          <div className={showRoles ? "" : "collapse"}>
            {logsDetailData?.data?.roleProfile?.map((role) => {
              return (
                <RoleMaker
                  role={role}
                  showRolePerms={showRolePerms}
                  handleRoleNameClick={handleRoleNameClick}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ChangesPage;
